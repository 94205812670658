import { useTranslation } from 'next-i18next';
import React from 'react';
import CommonFormLayout from 'components/domain/CommonFormLayout';
import CustomLabel from 'components/domain/CustomLabel';
import HomeTravelDestinationField from 'components/domain/home/HomeTravelDestinationField';
import SearchTravelDates from 'components/domain/search-travel-dates/search-travel-dates';
import TravelRoomsFieldContainer from 'components/domain/travelRooms/TravelRoomsFieldContainer';
import { useFourTravelRooms } from 'components/domain/travelRooms/useFourTravelRooms';

const HomeHotelSearchControlForm: React.FC = () => {
  const { isValid: isTravelRoomsValid, isEmpty } = useFourTravelRooms();
  const [t] = useTranslation('common');

  return (
    <CommonFormLayout
      travelDestination={
        <HomeTravelDestinationField
          disabled={!isTravelRoomsValid}
          customLabel={
            <CustomLabel htmlFor="travelDestination">
              {t('search_form_label.travel_destination')}
            </CustomLabel>
          }
        />
      }
      travelDates={
        <SearchTravelDates
          disabled={!isTravelRoomsValid}
          customLabel={
            <CustomLabel htmlFor="travelDates">
              {t('search_form_label.travel_dates')}
            </CustomLabel>
          }
        />
      }
      travelRooms={
        <TravelRoomsFieldContainer
          customLabel={
            <CustomLabel htmlFor={`travelers`}>
              {t('search_form_label.travelers')}
            </CustomLabel>
          }
        />
      }
      isSubmitAllowed={isTravelRoomsValid && !isEmpty}
    />
  );
};

export default HomeHotelSearchControlForm;
