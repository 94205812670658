import React from 'react';
import { useField } from '@hotelplan/components.common.forms';
import FlightSearchControlForm from 'components/domain/flight/FlightSearchControlForm';
import { SearchControlFormType } from 'components/domain/searchControl/SearchControl.types';
import HomeHotelSearchControlForm from './HomeHotelSearchControlForm';

const HomeSearchControlForm: React.FC = () => {
  const [formType] = useField<SearchControlFormType>('type' as const);

  return formType === 'HOTEL' ? (
    <HomeHotelSearchControlForm />
  ) : (
    <FlightSearchControlForm withCustomLabels />
  );
};

export default HomeSearchControlForm;
