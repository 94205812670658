import React, { FC } from 'react';
import { ITravelDestinationDisableableProps } from '@hotelplan/components.common.travel-destination';
import TravelDestinationFieldWithTopDestinations from 'components/domain/top-destination-autocomplete-suggestions/TravelDestinationFieldWithTopDestinations';
import TravelDestinationFieldWithCount from 'components/domain/travel-destination/TravelDestinationFieldWithCount';
import {
  GetHomeDestinationAutocompleteDocument,
  GetHomeDestinationAutocompleteQuery,
  GetHomeDestinationAutocompleteQueryVariables,
} from 'graphql/search/GetTravelDestinationAutocomplete.generated';

const HomeTravelDestinationField: FC<
  ITravelDestinationDisableableProps & { customLabel: React.ReactNode }
> = ({ disabled, customLabel }) => {
  return (
    <TravelDestinationFieldWithCount>
      <TravelDestinationFieldWithTopDestinations<
        GetHomeDestinationAutocompleteQuery,
        GetHomeDestinationAutocompleteQueryVariables
      >
        document={GetHomeDestinationAutocompleteDocument}
        customLabel={customLabel}
        disabled={disabled}
        dataMapper={data =>
          data
            ? {
                destinations: data.home.autocomplete.destinations,
                page: data.home.autocomplete.page,
              }
            : undefined
        }
        variablesMapper={variables => ({
          ...variables,
          ...(variables?.selected?.length
            ? { selected: variables.selected.filter(i => i) }
            : undefined),
        })}
      />
    </TravelDestinationFieldWithCount>
  );
};

export default HomeTravelDestinationField;
