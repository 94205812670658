import React from 'react';
import { TravelType } from '@hotelplan/graphql.types';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import SearchControlFormLayout from 'components/domain/searchControl/SearchControlFormLayout';
import { ExtraTravelType } from 'components/domain/travelTypes/TravelType.constants';
import TravelTypeField from 'components/domain/travelTypes/TravelTypeField';

const additionalTravelTypes: (TravelType | ExtraTravelType)[] = [
  ExtraTravelType.Roundtrip,
  ExtraTravelType.Apartment,
];

const SearchControlTravelTypeContainer: React.FC<{
  children?: React.ReactNode;
}> = props => {
  const { children } = props;
  const { mobile } = useDeviceType();

  return (
    <SearchControlFormLayout
      travelTypes={
        <TravelTypeField
          mainTravelTypes={[
            TravelType.Package,
            TravelType.Flight,
            TravelType.Hotel,
            ExtraTravelType.Cruise,
            ...(mobile ? [] : additionalTravelTypes),
          ]}
          dropDownTravelTypes={mobile ? additionalTravelTypes : []}
        />
      }
    >
      {children}
    </SearchControlFormLayout>
  );
};

export default SearchControlTravelTypeContainer;
