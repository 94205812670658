import { DocumentNode } from 'graphql';
import { useState } from 'react';
import { PageType } from '@hotelplan/graphql.types';
import { useLazyQuery } from '@hotelplan/libs.context.req-ctx';
import { useGSSContext } from '@hotelplan/libs.gss';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { useTransfer } from '@hotelplan/libs.transfer';
import { mapFormStateToFlightSearchControlCriteriaInput } from 'components/domain/flight/Flight.mappers';
import { useFlightSearchTimestamps } from 'components/domain/flightSRL';
import { useAddHistoryItem } from 'components/domain/searchControl/useAddHistoryItem';
import { useGetFlightHomeToFlightSrlUrlLazyQuery } from 'graphql/flight/GetFlightHomeToFlightSrlUrl.generated';
import { InternalLinkFragmentFragment } from 'graphql/link/InternalLink.generated';
import {
  IFlightSearchControlFormState,
  ISearchControlFormState,
  ISearchControlState,
} from './SearchControl.types';

export const useNoOrlModal = () => {
  const { transfer } = useTransfer();
  const [isNoOrlModalShowed, showNoOrlModal, closeNoOrlModal] = useToggleState(
    false
  );
  const [noOrlLink, setNoOrlLink] = useState<string | undefined>();
  const [productName, setProductName] = useState<string | undefined>();

  return {
    isModalShowed: isNoOrlModalShowed,
    onRenewSearch: () => {
      if (noOrlLink) {
        transfer(
          { targetPageType: PageType.Srl, uri: noOrlLink },
          false,
          false
        );
      }
      setNoOrlLink(undefined);
      setProductName(undefined);
      closeNoOrlModal();
    },
    onClose: () => {
      setNoOrlLink(undefined);
      setProductName(undefined);
      closeNoOrlModal();
    },
    setNoOrlLink,
    showNoOrlModal,
    productName,
    setProductName,
  };
};

export type IVariablesLinkMapper<TGetLinkVariables> = (
  formState: ISearchControlState
) => TGetLinkVariables;

export type IDataLinkMapper<TGetLinkQuery> = (
  linkData: TGetLinkQuery
) => InternalLinkFragmentFragment;

export default function useSearchControlApply<
  TGetLinkQuery extends {},
  TGetLinkVariables
>(
  getLinkQuery: DocumentNode,
  variablesLinkMapper: IVariablesLinkMapper<TGetLinkVariables>,
  dataLinkMapper: IDataLinkMapper<TGetLinkQuery>,
  initialFormValues?: ISearchControlState | null
) {
  const { transfer } = useTransfer();
  const { setGSS, setFGSS } = useGSSContext<
    ISearchControlFormState,
    IFlightSearchControlFormState
  >();
  const { setFlightSearchRequestTimestamp } = useFlightSearchTimestamps();

  const noOrlModal = useNoOrlModal();

  const addHistoryItem = useAddHistoryItem();

  const { setNoOrlLink, showNoOrlModal, setProductName } = noOrlModal;

  const [getLink] = useLazyQuery<TGetLinkQuery, TGetLinkVariables>(
    getLinkQuery,
    {
      onCompleted(linkData) {
        const url = dataLinkMapper(linkData);

        if (url.targetPageType === PageType.Page_404) {
          setNoOrlLink(url.uri);
          showNoOrlModal();
        } else {
          url && transfer(url);
        }
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  // @TODO Temporary solution due to lack of API capabilities
  const [getFlightHomeToFlightSrlUrl] = useGetFlightHomeToFlightSrlUrlLazyQuery(
    {
      onCompleted(urlData) {
        transfer(urlData.flightHome.url);
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  function applySearch(formState: ISearchControlState) {
    if (formState.type === 'HOTEL') {
      setGSS(initialFormValues || {}, formState);

      getLink({
        variables: variablesLinkMapper(formState),
      });
      setProductName(
        (formState.travelDestination?.destinations || [])
          .map(x => x.name)
          .join(', ')
      );
    } else {
      setFGSS(initialFormValues || {}, formState);
      setFlightSearchRequestTimestamp();
      getFlightHomeToFlightSrlUrl({
        variables: {
          flightSearchControlCriteriaInput: mapFormStateToFlightSearchControlCriteriaInput(
            formState as IFlightSearchControlFormState
          ),
        },
      });
    }
    addHistoryItem(formState);
  }

  return {
    applySearch,
    noOrlModal,
  };
}
