import capitalize from 'lodash/capitalize';
import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { Image } from '@hotelplan/components.common.image';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { mapFlightClass } from '@hotelplan/libs.mappers';
import { formatDate } from '@hotelplan/libs.utils';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { IFlightSectionProps } from 'components/domain/flightSection/FlightSection.types';

const FlightSectionWrapper = styled.div(
  sx2CssThemeFn({
    py: 3,
    px: [3, '30px'],
  })
);

const FlightSectionContainer = styled.div({
  display: 'flex',
});

const ArrivalTimeWrap = styled.div(({ theme: { media } }) => ({
  display: 'flex',
  [media.tablet]: {
    order: 1,
  },
}));

const ArrivalTime = styled.p(({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn({
    ...FONT_SIZE.XS,
    marginRight: [0, 2],
  })
);

const DepartureTime = styled(ArrivalTime)({
  textAlign: 'right',
});

const AirlineLogoWrapper = styled.div(
  sx2CssThemeFn({
    flexShrink: 0,
    flex: [2, 1],
    py: 1,
    alignSelf: ['center', 'auto'],
    mb: [1, 0],
  })
);

const AirlineLogo = styled(Image).attrs({
  lazy: false,
  resized: [],
  alt: `airline logo`,
})(
  sx2CssThemeFn({
    width: ['70px', '100px'],
    pt: 2,
  })
);

export const Airport = styled.span(
  ({ theme: { FONT_SIZE } }) =>
    sx2CssThemeFn({
      position: 'relative',
      ...FONT_SIZE.LARGE,
      ':hover .tooltip': {
        visibility: 'visible',
      },
    }),
  ({ theme }) => ({
    '.tooltip': { ...theme.tooltip, top: '-175%' },
  })
);

const DepartureAirport = styled(Airport.withComponent(`p`))({
  textAlign: 'right',
});

const ArrivalAirport = styled(Airport)(({ theme: { media, space } }) => ({
  [media.tablet]: {
    marginRight: space[2],
  },
}));

const ArrivalDate = styled.span(({ theme: { FONT_SIZE, colors, media } }) =>
  sx2CssThemeFn({
    ...FONT_SIZE.SMALL,
    color: colors.darkGray,
    [media.mobile]: {
      fontSize: '12px',
      lineHeight: '18px',
    },
    letterSpacing: '0.3px',
    width: '100%',
  })
);

const DepartureDate = styled(ArrivalDate)({
  textAlign: 'right',
});

const FlightDetailsWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    flex: [2, 1],
    px: 1,
    py: 1,
    flexDirection: ['column', 'row'],
    flexWrap: [null, 'wrap'],
    letterSpacing: [null, '0.7px'],
  })
);

const FlightDetailsFirstWrapper = styled(FlightDetailsWrapper)(
  sx2CssThemeFn({
    justifyContent: [null, 'flex-end'],
  })
);

const FlightDetailsSecondWrapper = styled(FlightDetailsWrapper)({
  minWidth: '63px',
});

const FlightDurationWrap = styled.div(({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn({
    display: 'flex',
    ...FONT_SIZE.SMALL,
    letterSpacing: [null, '0.3px'],
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: ['center', 'auto'],
    mb: [1, 0],
    flex: '3',
    px: 1,
    py: 1,
  })
);

const STOP_LOCATIONS_WIDTH_MOBILE = 96;
const STOP_LOCATIONS_WIDTH_DESKTOP = 280;

const StopLocationsSection = styled.div(
  sx2CssThemeFn({
    width: [
      `${STOP_LOCATIONS_WIDTH_MOBILE}px`,
      `${STOP_LOCATIONS_WIDTH_DESKTOP}px`,
    ],
    position: 'relative',
    height: '1px',
    backgroundColor: 'black',

    '.flight-time-marker': {
      width: ['5px', '7px'],
      height: ['5px', '7px'],
      position: 'absolute',
      top: ['-2px', '-3px'],
      right: '0px',
      background: 'black',
      borderRadius: '50%',
    },
  })
);

const StopLocation = styled.div<{ index: number; count: number }>(
  sx2CssThemeFn({
    width: ['5px', '7px'],
    height: ['5px', '7px'],
    position: 'absolute',
    top: ['-2px', '-2.5px'],
    backgroundColor: 'white',
    border: '1px solid',
    borderRadius: '50%',
  }),
  ({ index: i, count }) =>
    sx2CssThemeFn({
      left: [
        `${(STOP_LOCATIONS_WIDTH_MOBILE / (count + 1) - 2.5) * (i + 1)}px`,
        `${(STOP_LOCATIONS_WIDTH_DESKTOP / (count + 1) - 3) * (i + 1)}px`,
      ],
    })
);

const StopsCount = styled.span({
  textAlign: 'center',
});

const CabinClasses = styled.span.attrs({
  'data-id': `flight-cabin-classes`,
})(({ theme: { colors, fontSizes, space } }) => ({
  color: colors.darkGray,
  fontSize: fontSizes[1],
  marginLeft: space[2],
}));

const FlightSection: React.FC<IFlightSectionProps> = props => {
  const {
    airlineLogo,
    departureTime,
    departureAirport,
    departureAirportPlaceholder,
    arrivalAirportPlaceholder,
    flightDuration,
    stopLocations,
    arrivalTime,
    arrivalAirport,
    flightClasses,
    displayFlightDetails,
    arrivalDate,
    departureDate,
    isReturn,
    className,
  } = props;

  const [t, i18n] = useTranslation('orl');

  const stopsCount = stopLocations.length;
  const { mobile } = useDeviceType();

  return (
    <FlightSectionWrapper className={className}>
      <FlightSectionContainer>
        <AirlineLogoWrapper>
          <AirlineLogo src={airlineLogo} />
        </AirlineLogoWrapper>
        <FlightDetailsFirstWrapper>
          <DepartureDate
            data-id={`flight-departure-date-${isReturn ? 'out' : 'in'}`}
          >
            {capitalize(
              formatDate(
                departureDate,
                mobile ? 'dd.MM.yyyy' : 'EE dd.MM.yyyy',
                i18n.language
              )
            )}
          </DepartureDate>
          <DepartureTime
            data-id={`flight-departure-time-${isReturn ? 'out' : 'in'}`}
          >
            {departureTime}
          </DepartureTime>
          <DepartureAirport data-id="flight-departure-airport">
            {departureAirport}
            <span className="tooltip" data-id="flight-departure-placeholder">
              {departureAirportPlaceholder}
            </span>
          </DepartureAirport>
        </FlightDetailsFirstWrapper>
        <FlightDurationWrap>
          <span data-id={`flight-duration-${isReturn ? 'out' : 'in'}`}>
            {flightDuration}
          </span>
          <StopLocationsSection>
            {stopLocations.map((_, i) => {
              return (
                <StopLocation key={i} index={i} count={stopLocations.length} />
              );
            })}
            <div className="flight-time-marker" />
          </StopLocationsSection>
          <StopsCount>
            {stopsCount === 0
              ? t('flight.direct')
              : `${t('stops.count', {
                  count: stopsCount,
                })} in ${stopLocations.join('/')}`}
          </StopsCount>
        </FlightDurationWrap>
        <FlightDetailsSecondWrapper>
          <ArrivalDate
            data-id={`flight-arrival-date-${isReturn ? 'out' : 'in'}`}
          >
            {capitalize(
              formatDate(
                arrivalDate,
                mobile ? 'dd.MM.yyyy' : 'EE dd.MM.yyyy',
                i18n.language
              )
            )}
          </ArrivalDate>
          <ArrivalTimeWrap>
            <ArrivalTime
              data-id={`flight-arrival-time-${isReturn ? 'out' : 'in'}`}
            >
              {arrivalTime}
            </ArrivalTime>
          </ArrivalTimeWrap>
          <ArrivalAirport data-id="flight-arrival-airport">
            {arrivalAirport}
            <span className="tooltip" data-id="flight-arrival-placeholder">
              {arrivalAirportPlaceholder}
            </span>
          </ArrivalAirport>
        </FlightDetailsSecondWrapper>
      </FlightSectionContainer>
      {displayFlightDetails && (
        <CabinClasses>
          {flightClasses
            .map(flightClass => {
              return t(mapFlightClass[flightClass].label);
            })
            .join(', ')}
        </CabinClasses>
      )}
    </FlightSectionWrapper>
  );
};

export default React.memo(FlightSection, (prev, next) => {
  if (prev.offerId === next.offerId && !!next.offerId) return true;
  return false;
});
