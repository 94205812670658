import React from 'react';
import styled from 'styled-components';
import { SearchControlSkeleton } from 'components/domain/searchControl/search-control-skeleton';

const SearchControlSkeletonWrapper = styled(SearchControlSkeleton)(
  ({ theme }) => theme.forms.bannerForm || {}
);

const BannerSearchFormSkeleton: React.FC = () => {
  return <SearchControlSkeletonWrapper height={60} />;
};

export default BannerSearchFormSkeleton;
