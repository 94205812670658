import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import { InternalLinkFragmentFragmentDoc } from 'graphql/link/InternalLink.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetHomeLinkQueryVariables = Types.Exact<{
  homeSearchControlCriteriaInput: Types.HomeSearchControlCriteriaInput;
  context?: Types.InputMaybe<Types.RequestContextInput>;
}>;

export type GetHomeLinkQuery = {
  __typename?: 'Query';
  home: {
    __typename?: 'HomeContext';
    link: {
      __typename?: 'InternalLink';
      caption?: string | null;
      uri: string;
      targetPageType: Types.PageType;
    };
  };
};

export const GetHomeLinkDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetHomeLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'homeSearchControlCriteriaInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'HomeSearchControlCriteriaInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'deviceType' },
                value: { kind: 'EnumValue', value: 'DESKTOP' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'link' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'searchCriteria' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'searchControl' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'homeSearchControlCriteriaInput',
                              },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'targetPageType' },
                      value: { kind: 'EnumValue', value: 'SRL' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'internalLinkFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...InternalLinkFragmentFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;

/**
 * __useGetHomeLinkQuery__
 *
 * To run a query within a React component, call `useGetHomeLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHomeLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHomeLinkQuery({
 *   variables: {
 *      homeSearchControlCriteriaInput: // value for 'homeSearchControlCriteriaInput'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetHomeLinkQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetHomeLinkQuery,
    GetHomeLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetHomeLinkQuery, GetHomeLinkQueryVariables>(
    GetHomeLinkDocument,
    options
  );
}
export function useGetHomeLinkLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetHomeLinkQuery,
    GetHomeLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetHomeLinkQuery,
    GetHomeLinkQueryVariables
  >(GetHomeLinkDocument, options);
}
export type GetHomeLinkQueryHookResult = ReturnType<typeof useGetHomeLinkQuery>;
export type GetHomeLinkLazyQueryHookResult = ReturnType<
  typeof useGetHomeLinkLazyQuery
>;
export type GetHomeLinkQueryResult = Apollo.QueryResult<
  GetHomeLinkQuery,
  GetHomeLinkQueryVariables
>;
