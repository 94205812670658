import { useApolloClient } from '@apollo/client';
import type { DocumentNode } from 'graphql';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { IFormApi } from '@hotelplan/components.common.forms';
import type {
  DeviceType,
  PageLanguage,
  TravelType,
} from '@hotelplan/graphql.types';
import { useRequestContext } from '@hotelplan/libs.context.req-ctx';
import { useGSSContext } from '@hotelplan/libs.gss';
import { mergeSearchControlState } from './SearchControl.mappers';
import {
  IFlightSearchControlFormState,
  ISearchControlFormState,
  ISearchControlState,
} from './SearchControl.types';
import useSearchControlApply, {
  IDataLinkMapper,
  IVariablesLinkMapper,
} from './useSearchControlApply';
import useSearchControlValues, {
  SearchControlDataMapper,
} from './useSearchControlValues';

interface IGetValuesQueryConfig<
  TGetValuesQuery extends {},
  TGetValuesVariables
> {
  query: DocumentNode;
  variablesMapper: (travelType?: TravelType) => TGetValuesVariables;
  dataMapper: SearchControlDataMapper<TGetValuesQuery>;
}

interface IGetLinkQueryConfig<TGetLinkQuery extends {}, TGetLinkVariables> {
  query: DocumentNode;
  variablesMapper: IVariablesLinkMapper<TGetLinkVariables>;
  dataMapper: IDataLinkMapper<TGetLinkQuery>;
}

export default function useSearchControlForm<
  TGetValuesQuery extends {},
  TGetValuesVariables,
  TGetLinkQuery extends {},
  TGetLinkVariables
>(
  getValuesQueryConfig: IGetValuesQueryConfig<
    TGetValuesQuery,
    TGetValuesVariables
  >,
  getLinkQueryConfig: IGetLinkQueryConfig<TGetLinkQuery, TGetLinkVariables>,
  objectId?: string
) {
  const prevObjectIdRef = useRef<string | undefined>(objectId);
  const isObjectIdChangedRef = useRef(false);
  const isObjectIdChanged = isObjectIdChangedRef.current;
  isObjectIdChangedRef.current = false;
  const client = useApolloClient();
  const { prefetch } = useRouter();
  const requestContext = useRequestContext<DeviceType, PageLanguage>();
  // NOTE: Keep first time loaded travel type to see if there are changes to save in GSS.
  const initialTravelTypeRef = useRef<TravelType | undefined>(undefined);
  const defaultTravelTypeRef = useRef<TravelType | undefined>(undefined);

  const formChangedValuesMap = useRef<{
    [type: string]: ISearchControlState | null;
  }>({
    HOTEL: null,
    FLIGHT: null,
  });

  if (isObjectIdChanged) {
    formChangedValuesMap.current = { HOTEL: null, FLIGHT: null };
  }

  const { gss, fgss, resetGSS } = useGSSContext<
    ISearchControlFormState,
    IFlightSearchControlFormState
  >();

  const formApiRef = useRef<IFormApi<ISearchControlState>>(null);
  const [travelType, setTravelType] = useState<TravelType | undefined>(
    undefined
  );

  const { data: originalInitialValues, loading } = useSearchControlValues<
    TGetValuesQuery,
    TGetValuesVariables
  >(
    getValuesQueryConfig.query,
    getValuesQueryConfig.variablesMapper(travelType),
    getValuesQueryConfig.dataMapper,
    data => {
      const mappedData = getValuesQueryConfig.dataMapper(data);

      // Updating cache for default travelType
      if (!travelType && mappedData?.travelType) {
        try {
          client.writeQuery<TGetValuesQuery, TGetValuesVariables>({
            query: getValuesQueryConfig.query,
            variables: {
              ...getValuesQueryConfig.variablesMapper(
                mappedData.travelType as TravelType
              ),
              context: requestContext,
            },
            data,
          });
        } catch (e) {
          // ignore writeCache errors
        }
      }

      // Detect objectId changing to process hard-reset of data
      if (prevObjectIdRef.current !== objectId) {
        isObjectIdChangedRef.current = true;
        defaultTravelTypeRef.current = undefined;
      }

      // NOTE: Update initial travel type with first time loaded travel type value.
      if (!initialTravelTypeRef.current || isObjectIdChangedRef.current) {
        initialTravelTypeRef.current = mappedData?.travelType as TravelType;
      }

      // Update prev ObjectId after data is loaded
      prevObjectIdRef.current = objectId;
    }
  );

  if (!defaultTravelTypeRef.current && originalInitialValues?.travelType) {
    defaultTravelTypeRef.current = originalInitialValues.travelType;
  }

  const initialValues = useMemo(
    () => mergeSearchControlState(originalInitialValues, gss, fgss),
    [originalInitialValues, gss, fgss]
  );

  useEffect(
    function prefetchSearchPages() {
      if (!initialValues) return;

      if (initialValues.type === 'HOTEL') {
        prefetch('/search');
      } else if (initialValues.type === 'FLIGHT') {
        prefetch('/flight-search');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialValues?.type]
  );

  useEffect(
    function formTypeChangeEffect() {
      if (initialValues) {
        if (
          initialValues.travelType &&
          initialValues.travelType !== travelType
        ) {
          setTravelType(initialValues.travelType as TravelType);
        }

        if (travelType) {
          formApiRef.current?.setValues({
            ...initialValues,
            ...formChangedValuesMap.current[initialValues.type],
            travelType,
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialValues?.type]
  );

  useEffect(
    function objectIdChangeEffect() {
      if (isObjectIdChanged && initialValues) {
        formApiRef.current?.setValues(initialValues);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isObjectIdChanged]
  );

  const onChange = useCallback((nextFormState: ISearchControlState) => {
    formChangedValuesMap.current[nextFormState.type] = nextFormState;
    setTravelType(nextFormState.travelType as TravelType);
  }, []);

  const onReset = useCallback(() => {
    const resetValues = {
      ...originalInitialValues,
      travelType: defaultTravelTypeRef.current,
    };
    resetGSS();
    formApiRef.current?.setValues(resetValues);
    onChange(resetValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalInitialValues]);

  const { applySearch, noOrlModal } = useSearchControlApply<
    TGetLinkQuery,
    TGetLinkVariables
  >(
    getLinkQueryConfig.query,
    getLinkQueryConfig.variablesMapper,
    getLinkQueryConfig.dataMapper,
    {
      ...originalInitialValues,
      travelType: initialTravelTypeRef.current,
    } as ISearchControlState
  );

  // ObjectId changes should reset form, e.g. Geo breadcrumbs transition
  const defaultLoading =
    (!travelType || prevObjectIdRef.current !== objectId) && loading;

  return {
    initialValues,
    loading,
    defaultLoading,
    travelType,
    formApiRef,
    onChange,
    onReset,
    onApply: applySearch,
    noOrlModal,
  };
}
