import React from 'react';
import styled from 'styled-components';
import { IImageProps, Image } from '@hotelplan/components.common.image';
import type { ITextBlockProps } from '@hotelplan/components.common.text-block';
import { useViewportSize } from '@hotelplan/libs.hooks-dom';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

interface IStyledTextBlockProps extends ITextBlockProps {
  fontColor: string;
  backgroundColor: string;
  backgroundImage: IImageProps;
}

const TextBlockTitle = styled.h1<{ color: string; backgroundColor: string }>(
  ({ theme: { FONT_SIZE }, color, backgroundColor }) =>
    sx2CssThemeFn({
      ...FONT_SIZE.M,
      position: 'relative',
      zIndex: 10,
      color,
      backgroundColor,
    })
);

const TextBlockWrapper = styled.div.attrs({
  'data-id': `text-block-wrapper`,
})<{
  backgroundColor: string;
  backgroundImage: boolean;
}>(
  ({ backgroundColor, backgroundImage }) =>
    sx2CssThemeFn(
      {
        variant: 'text.richText',
        position: 'relative',
        zIndex: 1,
        backgroundColor,
      },
      {
        ...(backgroundColor || backgroundImage
          ? {
              boxShadow: backgroundImage
                ? null
                : `0 0 0 100vmax ${backgroundColor}`,
              clipPath: `inset(0 -100vmax)`,
            }
          : {}),
      }
    ),
  ({ theme }) => theme.text.textBlockWrap || {}
);

const TextBlockText = styled.div<{ color: string; backgroundColor: string }>(
  ({ theme: { FONT_SIZE, text }, color, backgroundColor }) =>
    sx2CssThemeFn({
      ...FONT_SIZE.LARGE,
      ...text.textBlockWrap,
      pt: [2, 4],
      zIndex: 10,
      position: 'relative',
      color,
      backgroundColor,
    })
);

const TextBlockTextImage = styled(Image)<{ viewportWidth: string }>(
  ({ viewportWidth }) =>
    sx2CssThemeFn({
      position: 'absolute',
      objectFit: 'cover',
      display: 'block',
      width: viewportWidth,
      height: '100%',
      top: 0,
      bottom: 0,
      right: 0,
      zIndex: 1,
      left: '50%',
      transform: 'translateX(-50%)',
      maxWidth: 'none',
    })
);

const StyledTextBlock: React.FC<IStyledTextBlockProps> = ({
  text,
  title,
  className,
  id,
  backgroundImage,
  fontColor,
  backgroundColor,
}) => {
  const { widthPx: viewportWidth = '100vw' } = useViewportSize();

  return (
    <TextBlockWrapper
      className={`${className ? className : ''} text-block-wrapper`}
      backgroundColor={backgroundColor}
      backgroundImage={Boolean(backgroundImage)}
    >
      {title && (
        <TextBlockTitle
          className="text-block-title"
          dangerouslySetInnerHTML={{ __html: title }}
          color={fontColor}
          backgroundColor={backgroundImage ? null : backgroundColor}
        />
      )}

      <TextBlockText
        id={id}
        className="text-block-text"
        dangerouslySetInnerHTML={{ __html: text }}
        color={fontColor}
        backgroundColor={backgroundImage ? null : backgroundColor}
      />
      {backgroundImage ? (
        <TextBlockTextImage
          {...backgroundImage}
          viewportWidth={viewportWidth}
          className="text-block-image"
        />
      ) : null}
    </TextBlockWrapper>
  );
};

export default StyledTextBlock;
