import type { TFunction } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import CustomLabel from 'components/domain/CustomLabel';
import FlightHomeAirportField from 'components/domain/flight/FlightHomeAirportField';
import FlightFormLayout from 'components/domain/FlightFormLayout';
import { useFlightPartitions } from 'components/domain/flightPartitions/useFlightPartitions';
import { useFourTravelRooms } from 'components/domain/travelRooms/useFourTravelRooms';

interface IFlightSearchControlFormProps {
  handleCancel?: () => void;
  withCustomLabels?: boolean;
}

const renderDepartureAirportField = (
  t: TFunction,
  partitionIndex: number,
  withCustomLabels?: boolean
): React.ReactNode => {
  return (
    <FlightHomeAirportField
      name="departureAirport"
      parentItemIndex={partitionIndex}
      label={t('forms:departureAirport.placeholder')}
      placeholder={t('forms:departureAirport.placeholder')}
      customLabel={
        withCustomLabels ? (
          <CustomLabel htmlFor="departureAirport">
            {t('forms:departureAirport.label')}
          </CustomLabel>
        ) : null
      }
    />
  );
};

const renderArrivalAirportField = (
  t: TFunction,
  partitionIndex: number,
  withCustomLabels?: boolean
): React.ReactNode => {
  return (
    <FlightHomeAirportField
      name="arrivalAirport"
      parentItemIndex={partitionIndex}
      label={t('forms:arrivalAirport.placeholder')}
      placeholder={t('forms:arrivalAirport.placeholder')}
      customLabel={
        withCustomLabels ? (
          <CustomLabel htmlFor="arrivalAirport">
            {t('forms:arrivalAirport.label')}
          </CustomLabel>
        ) : null
      }
    />
  );
};

const FlightFormLayoutWrapper = styled(FlightFormLayout)(
  sx2CssThemeFn({
    px: [0, '30px'],
    pt: 4,
    pb: ['20px', '30px'],
  })
);

const FlightSearchControlForm: React.FC<IFlightSearchControlFormProps> = ({
  handleCancel,
  withCustomLabels,
}) => {
  const { isValid: isTravelRoomsValid, isEmpty } = useFourTravelRooms();
  const { areValid: areFlightPartitionsValid } = useFlightPartitions();

  return (
    <FlightFormLayoutWrapper
      renderArrivalAirportField={renderArrivalAirportField}
      renderDepartureAirportField={renderDepartureAirportField}
      isSubmitAllowed={
        areFlightPartitionsValid && isTravelRoomsValid && !isEmpty
      }
      handleCancel={handleCancel}
      withCustomLabels={withCustomLabels}
    />
  );
};

export default FlightSearchControlForm;
